.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: left;
}

.home-text {
  max-width: 600px;
}

.home-image {
  max-width: 100%;
  height: auto;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pattern-image {
  transition: transform 0.3s;
}

.pattern-image:hover {
  transform: scale(1.05);
}

.divider {
  margin: 64px 0;
}

.sticky-text {
  position: -webkit-sticky; /* Для Safari */
  position: sticky;
  top: 96px; /* Высота navbar плюс небольшой отступ */
  z-index: 1000; /* Убедитесь, что текст находится выше других элементов */
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (min-width: 600px) {
  .button-group {
    flex-direction: row;
  }
}

.tile {
  opacity: 0;
  transform: translateY(50px);
}

.tile.visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}
