.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1100;
}

.nav-link {
  margin-left: 20px;
  text-decoration: none;
  color: inherit;
}

.nav-link:hover {
  text-decoration: underline;
}
